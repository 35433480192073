<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">EventCalendars/Create</h4>
          <div>
            <router-link :to="{...previousRoute}">
              <span class="glow d-flex align-items-center"><i class='bx bx-undo'></i>&nbsp;Back</span>
            </router-link>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <form action="">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <div>
                      <label for="name">Name</label>
                      <input v-model="postEventCalendarData.name" type="text" class="form-control" id="name"
                             placeholder="Enter Event Name">
                    </div>
                    <div v-if="errors.eventCalendar.name" class="text-danger">{{ errors.eventCalendar.name }}</div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4">
                  <div class="form-group">
                    <label>Type *</label>
                    <VueMultiselect v-model="selectedType" class="" :options="typeList" :close-on-select="true"
                                    placeholder="Select type" label="name" track-by="name" :show-labels="false"
                                    :allow-empty="false"/>
                    <div class="text-danger" v-if="errors.eventCalendar.type">{{ errors.eventCalendar.type }}</div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4">
                  <div class="form-group">
                    <label>Jurisdiction *</label>
                    <VueMultiselect v-model="selectedJurisdiction" class="" :options="jurisdictionOptions" :close-on-select="true"
                                    label="name" track-by="value" :show-labels="false" :allow-empty="false"/>
                    <div class="text-danger" v-if="errors.eventCalendar.jurisdiction">{{ errors.eventCalendar.jurisdiction }}</div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4">
                  <div class="form-group">
                    <label>Event Date</label>
                    <div class="d-flex align-items-center date-picker-wrapper">
                      <div class="d-flex align-items-center">
                        <i class='bx bx-calendar'></i>
                      </div>
                      <div class="w-100 pl-1 ">
                        <div class="w-100 ">
                          <DatePicker v-model="postEventCalendarData.date"
                                      :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}"
                                      :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                            <template v-slot="{ inputValue, inputEvents }">
                              <input :value="inputValue" v-on="inputEvents" class="form-control"/>
                            </template>
                          </DatePicker>
                        </div>
                      </div>
                    </div>
                    <div class="text-danger" v-if="errors.eventCalendar.date">{{ errors.eventCalendar.date }}</div>
                  </div>
                </div>


                <div class="col-12">
                  <div class="form-group">
                    <label for="information">Information</label>
                    <textarea class="form-control" v-model="postEventCalendarData.information" id="information" placeholder="Write about the event ..."></textarea>
                    <div class="text-danger" v-if="errors.eventCalendar.information ">{{ errors.eventCalendar.information  }}</div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label for="moreInformation">More Information</label>
                    <textarea class="form-control" v-model="postEventCalendarData.more_information " id="moreInformation" placeholder="Write about more information. i.e link, etc ..."></textarea>
                    <div class="text-danger" v-if="errors.eventCalendar.moreInformation ">{{ errors.eventCalendar.moreInformation }}</div>
                  </div>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-12">
                  <div class="d-flex justify-content-end">
                    <button @click="createSingleEventCalendar" type="button" class="btn btn-primary update-todo px-4">Create
                      Event Calendar
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
// components
import AppLayout from '@/layouts/backEnd/AppLayout.vue'

// core packages
import {mapActions, mapGetters} from 'vuex';

// packages
import VueMultiselect from 'vue-multiselect';
import {DatePicker} from 'v-calendar';

// mixins
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";

// css
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import Authorization from "@/components/backEnd/mixins/Authorization";

export default {
  components: {
    AppLayout,
    VueMultiselect,
    DatePicker

  },
  name: "EventCalendarCreate.vue",

  mixins: [ShowToastMessage, Loader, Authorization],

  data() {
    return {
      getSettingsParams: {
        type: ['default'],
        key: ['default_state'],

      },
      postEventCalendarData: {
        name: '',
        type: 0,
        date: '',
        jurisdiction: '',
        information : '',
        more_information: '',
      },

      typeList: [
        {
          name: 'Public Holiday',
          value: 0,
        },
      ],

      selectedType: {
        name: 'Public Holiday',
        value: 0,
      },

      selectedJurisdiction: {
        value: '',
        name: "Select Jurisdiction",
      },

      errors: {
        eventCalendar: {
          name: '',
          date: '',
          jurisdiction: '',
          type: '',
          moreInformation : '',
          information : '',
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',
    }),
    redirectRouteName() {
      return this.can('event-calendar-view-any') ? 'appEventCalendarList' : 'appEventCalendarCreate';
    },
    jurisdictionOptions() {
      return [ ...this.$store.getters["appSettings/settingDefaultState"].value];
    },
  },

  watch: {
    selectedType(selectedType) {
      this.postEventCalendarData.type = selectedType.value;
    },
    selectedJurisdiction(selectedJurisdiction) {
      this.postEventCalendarData.jurisdiction = selectedJurisdiction.value;
    },
  },

  methods: {
    ...mapActions({
      postEventCalendar: 'appEventCalendars/postEventCalendar',
      getSettings: 'appSettings/getSettings',
    }),

    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

    async createSingleEventCalendar() {
      this.loader(true);
      await this.postEventCalendar(this.postEventCalendarData).then(async (response) => {
        this.loader(false);
        if (response.status === 200 || response.status === 201) {
          const toastObj = {
            message: 'Event calendar created successful.',
            type: 'success'
          };

          this.showToastMessage(toastObj);

          await this.$router.push({
            name: this.redirectRouteName,
          });

        }

        this.errors.eventCalendar.name = response.errors && response.errors.name ? response.errors.name[0] : '';
        this.errors.eventCalendar.information  = response.errors && response.errors.information  ? response.errors.information [0] : '';
        this.errors.eventCalendar.moreInformation  = response.errors && response.errors.more_information ? response.errors.more_information[0] : '';
        this.errors.eventCalendar.type = response.errors && response.errors.type ? response.errors.type[0] : '';
        this.errors.eventCalendar.jurisdiction = response.errors && response.errors.jurisdiction ? response.errors.jurisdiction[0] : '';
        this.errors.eventCalendar.date = response.errors && response.errors.date ? response.errors.date[0] : '';

        if (response.message) {
          this.showToastMessage(response);
        }

      })
    }
  },
  async mounted() {
    this.loader(true);
    await this.getSettingList();
    this.loader(false);
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>

.multiselect__tags:focus-within {

  /* border:1px solid red !important; */
  border-color: #5A8DEE;

}

.multiselect__tags {
  min-height: 0;
  max-height: 38px !important;
  border-color: #DFE3E7;
}

.ql-editor {
  min-height: 200px !important;
}
</style>
